/** @format */

import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from '@/firebaseinit.js';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   redirect: "/login"
  // },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/translate',
    name: 'translate',
    component: () => import('../views/Translate.vue'),
    meta: {
      requiresAuth: true
    }
  }
  // {
  //   path: "/",
  //   name: "translate",
  //   component: () => import("../views/Translate.vue")
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/Login.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const authenticatedUser = await firebase.authenticatedUser();
  if (requiresAuth && !authenticatedUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
