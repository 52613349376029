/** @format */

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAIWdL9Z1Q70CzpmDGvh40NS8KXawr-oDg',
  authDomain: 'ccc-translation-gr.firebaseapp.com',
  projectId: 'ccc-translation-gr',
  // databaseURL:
  //   "https://ccc-translation-gr-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: 'ccc-translation-gr.appspot.com',
  messagingSenderId: '908575077674',
  appId: '1:908575077674:web:935071bdff208d371f1a53',
  measurementId: 'G-VBP6YRXCQ7',
  languageCode: 'pl'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
firebase.functions();
const db = firebase.firestore();

export const functions = firebase.app().functions('europe-west1');
const docRef = db.collection('users').doc('ccc-translate');

function getFunctionUrl() {
  var currentLocation = window.location;
  if (currentLocation.origin === 'https://translate.ccc.yosh.ai') {
    return 'https://send-email-hby2elejuq-lm.a.run.app';
  } else if (currentLocation.origin === 'https://ccc-translate-dev-hby2elejuq-ew.a.run.app') {
    return 'https://send-email-dev-hby2elejuq-lm.a.run.app';
  } else {
    return 'http://127.0.0.1:8080';
  }
}

export async function startFunctionsRequest(email) {
  console.log(`email = ${JSON.stringify(email)}`);
  const apiUrl = getFunctionUrl();
  // 'https://europe-west1-ccc-translation-gr.cloudfunctions.net/sendMessageTest';
  console.log(`apiurl = ${apiUrl}`);
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(async function(token) {
      console.log(`token = ${token}`);
      return fetch(apiUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(email) // body data type must match "Content-Type" header
      }).then(response => response.json());
    });
}

// export const

firebase.authenticatedUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        docRef
          .get()
          .then(doc => {
            if (doc.exists) {
              if (user.uid in doc.data()) {
                unsubscribe();
                resolve(user);
              } else {
                resolve(false);
              }
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!');
            }
          })
          .catch(error => {
            console.log('Error getting document:', error);
          });
      } else {
        resolve(false);
      }
    }, reject);
  });
};

export default firebase;
